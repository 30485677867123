<template>
  <div class="flex relative w-full py-3">
    <div class="absolute h-full left-1 top-2 z-10 mt-4">
      <div
        class="-top-3 -ml-4 h-11 w-11 absolute text-gray-400 bg-white p-1 rounded-full flex items-center justify-center"
        :class="{
          'bg-white': !is_external
        }"
      >
        <ProfileImage :user="user" :is_external="is_external" />
      </div>
    </div>
    <div
      class="absolute h-full left-20 top-2 mt-4 bg-gray-200 border-r-2 border-gray-200"
      v-if="showConnection"
    />
    <div class="ml-14 w-full">
      <div class="relative" :class="{ 'border rounded bg-gray-50': message }">
        <div
          class="w-4 overflow-hidden inline-block absolute -left-4 top-2"
          :style="caretDistance"
        >
          <div
            class="h-16 bg-gray-50 -rotate-45 transform origin-top-right border-l border-t border-gray-300"
          />
        </div>
        <slot name="right">
          <div
            class="border-b border-gray-200 flex w-full justify-between items-center px-3 py-2"
            v-if="message"
          >
            <div class="font-semibold flex flex-col text-sm">
              <span v-if="!is_external">
                {{ user.name }}
              </span>
              <span v-if="is_external">
                {{ message.extras.from }}
              </span>
              <span class="text-xs font-normal ml-1">
                ({{ getFromNowDateTime(message.inserted_at) }})
              </span>
            </div>

            <i
              class="far fa-edit cursor-pointer"
              :class="{
                'text-blueGray-300 pointer-events-none':
                  message.user_id != me.id
              }"
              @click="editMode = !editMode"
            />
          </div>
          <div class="px-3 pt-3" v-if="editMode">
            <Editor :body="message.body" v-model="message.body" />
            <button
              class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs my-3 px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
              @click="updateComment"
            >
              <i class="fas fa-comment-alt" /> {{ $t("update") }}
            </button>
          </div>
          <template v-if="message && !editMode">
            <div
              v-html="message.body"
              class="px-5 py-3 overflow-hidden bg-white rounded"
            />
            <div
              class="px-5 -mt-3 pb-3 bg-white text-xs text-blueGray-500 font-normal ml-1"
              v-if="message.edited"
            >
              ({{ $t("edited") }})
            </div>
          </template>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import formatting from "../mixins/formatting";
import userMixins from "../mixins/userMixins";
import Editor from "@/components/Editor.vue";
import ProfileImage from "./ProfileImage.vue";

export default {
  name: "TimelineLayout",
  mixins: [formatting, userMixins],
  props: {
    message: {
      type: Object,
      required: false
    },
    user: {
      type: Object,
      required: false
    },
    is_external: {
      type: Boolean,
      default: false
    },
    showConnection: {
      type: Boolean,
      required: true
    },
    caretDistance: {
      type: String,
      default: ""
    }
  },
  components: { Editor, ProfileImage },
  data() {
    return {
      editMode: false
    };
  },
  computed: {
    me() {
      return this.$store.state?.accounts.user;
    }
  },
  methods: {
    updateComment() {
      this.$store.dispatch("tickets/updateComment", this.message);
      this.editMode = false;
    }
  }
};
</script>
