<template>
  <div class="ticketDetail">
    <div class="fixed top-0 min-w-full bg-gray-600 md:pt-6 pb-48 pt-12" />
    <div
      class="flex px-8 md:px-14 mt-8 flex-wrap justify-between lg:flex-row-reverse"
    >
      <div class="w-full lg:w-1/3 lg:pr-4 lg:pl-4">
        <!-- <div
          class="w-full lg:mx-4 mb-6 py-4 relative shadow-lg rounded-lg flex justify-items-center items-center flex-col min-w-0 break-words bg-white xl:mb-5"
        >
          <QrCode
            v-if="ticket.qr_id"
            :item="ticket"
            nameKey="subject"
            :borderLess="true"
            :qronly="true"
            :key="ticket.qr_id"
          />
        </div> -->
        <SlideUp>
          <div
            class="relative flex flex-col w-full mb-6 lg:mx-4 border-0 rounded-lg overflow-hidden shadow-lg bg-white py-4"
          >
            <h3
              class="px-8 py-4 cursor-pointer flex justify-between items-center font-semibold text-blueGray-500 uppercase text-xs"
            >
              <span> <i class="fas fa-tag" /> {{ $t("ticket labels") }} </span>
              <PopoverWrapper
                v-if="ticket_tags && ticket"
                :items="ticket_tags"
                @handleItemChange="handleTagChange"
                :selectedItems="selectedTags"
                :hasLabelLink="true"
              />
            </h3>
            <div class="px-4 py-3" style="min-height: 100px">
              <div class="text-center mt-4">
                <h3
                  class="text-red-600 bg-rose-100 px-2 py-1 inline"
                  v-if="ticket.tags && ticket.tags.length == 0"
                >
                  {{ $t("no labels selected yet") }}
                </h3>
              </div>
              <label-display
                v-for="tag in ticket.tags"
                :key="`${tag.id}-tag`"
                :label="tag"
              />
            </div>
            <h3
              class="px-8 py-4 border-t cursor-pointer flex justify-between items-center font-semibold text-blueGray-500 uppercase text-xs"
            >
              <span> <i class="fas fa-user" /> {{ $t("recipients") }} </span>
              <PopoverWrapper
                :isUser="true"
                :items="users"
                @handleItemChange="handleUserChange"
                :selectedItems="selectedUsers"
                :hasLabelLink="false"
              />
            </h3>
            <div class="px-4 py-3" style="min-height: 100px">
              <div
                class="text-center mt-4"
                v-if="
                  ticket.internal_recipients &&
                  ticket.internal_recipients.length == 0
                "
              >
                <h3 class="text-red-600 bg-rose-100 px-2 py-1 inline">
                  {{ $t("no users selected yet") }}
                </h3>
              </div>
              <div
                v-for="(
                  internal_recipient, index
                ) in ticket.internal_recipients"
                :key="`${internal_recipient.id}-internal_recipient`"
                class="border-gray-300 px-2 py-2 flex"
                :class="{
                  'bg-blueGray-50': index % 2 != 0,
                  'border-t': index != 0
                }"
              >
                <div class="mr-3">
                  <ProfileImage :user="internal_recipient" />
                </div>
                <div>
                  {{ internal_recipient.name }}
                  <div class="text-xs text-gray-600 ml-2">
                    {{ internal_recipient.email }}
                  </div>
                </div>
              </div>
            </div>
            <div class="border-gray-300 py-3 flex justify-center flex-col">
              <label
                class="px-8 pb-2 pt-4 border-t cursor-pointer flex justify-between items-center font-semibold text-blueGray-500 uppercase text-xs"
                for=""
              >
                {{ $t("asset status") }}
              </label>
              <el-dropdown
                v-if="bike"
                trigger="click"
                @command="updateBikeStatus"
              >
                <div class="el-dropdown-link px-8 py-1 rounded">
                  <LabelDisplay v-if="bike.status" :label="bike.status" />
                  <span v-else>
                    {{ $t("no status yet") }}
                  </span>
                </div>

                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="status in statuses"
                    :key="status.id"
                    class="flex justify-items-center items-center"
                    :command="status.id"
                  >
                    <div class="flex items-center justify-between w-full">
                      <span class="flex items-center">
                        <div
                          class="h-5 w-5 rounded-full mr-1"
                          :style="`background:${status.color}`"
                        />
                        {{ status.name }}
                      </span>
                      <i
                        class="fas fa-check text-green-500 ml-1"
                        v-if="bike.status && bike.status.name == status.name"
                      />
                    </div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div
              class="border-gray-300 py-3 flex justify-center flex-col"
              v-for="s in selects"
              :key="s.key"
            >
              <label
                class="px-8 pb-2 pt-4 border-t cursor-pointer flex justify-between items-center font-semibold text-blueGray-500 uppercase text-xs"
                for=""
              >
                {{ $t(s.name) }}
              </label>
              <el-select
                clearable
                filterable
                class="w-full px-8"
                v-model="ticket[s.key]"
                placeholder="Select"
                @change="index => selectChange(index, s)"
              >
                <el-option
                  v-for="item in options[s.options]"
                  :key="item.id"
                  :label="`${item[s.optionLabelKey]}`"
                  :value="item.id"
                />
              </el-select>
            </div>
          </div>
        </SlideUp>
      </div>
      <SlideUp>
        <div class="w-full lg:w-2/3">
          <div
            class="relative flex flex-col w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
            v-if="ticket && ticket.id"
          >
            <div class="rounded-t bg-white mb-0 px-6 py-6">
              <div
                class="text-center flex justify-between items-center relative"
              >
                <input
                  v-if="editmode"
                  class="border px-3 py-2 w-full block"
                  v-model="subjectEdit"
                  type="text"
                />
                <h6
                  v-if="!editmode"
                  class="text-blueGray-700 text-xl font-bold flex items-center text-left px-3"
                  :class="{ 'bg-red-100': ticket.is_closed }"
                >
                  <i
                    class="fas fa-arrow-left cursor-pointer mr-3"
                    @click="lastPage"
                  />
                  <span
                    class="text-blueGray-700 text-xl font-bold flex flex-col text-left py-1 px-1"
                  >
                    <span class="flex items-center">
                      <span
                        class="font-semibold text-rose-600"
                        v-if="ticket.is_closed"
                      >
                        <i class="fas fa-window-close text-rose-600" />
                        [{{ $t("closed") }}]
                      </span>
                      <span>
                        {{ ticket.subject }}
                      </span>
                    </span>
                    <span
                      class="text-blueGray-500 text-sm font-semibold -mt-1"
                      v-if="ticket.supplier"
                    >
                      <router-link
                        :to="{
                          name: 'Supplier-Detail',
                          params: {
                            id: ticket.supplier_id
                          }
                        }"
                      >
                        {{ ticket.supplier.name }}
                      </router-link>
                      -
                      {{ getAddress(ticket.supplier.address) }}
                    </span>
                  </span>
                  <!-- <span
                    @click="handleDelete"
                    class="text-rose-500 border-b-4 border-rose-500 cursor-pointer text-xs"
                  >
                    {{ $t("delete") }}
                  </span> -->
                </h6>

                <div
                  class="text-blueGray-500 text-sm font-bold flex items-center ml-5"
                >
                  <span class="mr-2"> {{ $t("due") }}: </span>
                  <span v-if="!editmode">
                    <span
                      class="text-sm font-medium"
                      v-if="dueDateStatus == ''"
                    >
                      {{ ticket.due_date }}
                    </span>
                    <span
                      class="px-2 mr-2"
                      :class="{
                        'border-2 border-red-800 text-red-800':
                          dueDateStatus == 'Past due',
                        'border-2 border-indigo-800 text-indigo-800':
                          dueDateStatus == 'Due today'
                      }"
                    >
                      {{ dueDateStatus }}
                    </span>
                  </span>

                  <el-date-picker
                    v-if="editmode"
                    v-model="dueDateEdit"
                    type="date"
                    :placeholder="$t('Pick a due date')"
                  />
                  <!-- <span
                    v-if="!editmode"
                    class="bg-coolGray-500 cursor-pointer text-white active:bg-coolGray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    @click="editmode = true"
                  >
                    <i class="fas fa-edit cursor-pointer" />
                    {{ $t("edit") }}
                  </span> -->
                  <span class="ml-2 flex items-center" v-if="editmode">
                    <span
                      class="flex items-center cursor-pointer bg-coolGray-500 text-white active:bg-coolGray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                      @click="updateTicket"
                    >
                      <i class="fas fa-save cursor-pointer mr-1" />
                      {{ $t("save") }}
                    </span>
                    <span class="cursor-pointer" @click="editmode = false">
                      {{ $t("cancel") }}
                    </span>
                  </span>

                  <el-popover
                    v-if="!editmode"
                    placement="bottom"
                    width="170"
                    trigger="click"
                    popper-class="pzero-popover"
                  >
                    <div>
                      <div
                        v-if="!editmode"
                        class="py-3 px-4 cursor-pointer hover:bg-gray-100"
                        @click="editmode = true"
                      >
                        <i class="fas fa-edit cursor-pointer mr-2" />
                        {{ $t("edit") }}
                      </div>
                      <div
                        v-if="!editmode"
                        class="border-t py-3 px-4 cursor-pointer hover:bg-gray-100"
                        @click="sendEmailDialog = true"
                      >
                        <i class="fas fa-paper-plane cursor-pointer mr-2" />
                        {{ $t("notify supplier") }}
                      </div>
                      <div
                        @click="handleDelete"
                        class="border-t py-3 px-4 cursor-pointer hover:bg-gray-100 text-rose-500 border-b-4"
                      >
                        <i class="fas fa-trash-alt cursor-pointer mr-2" />
                        {{ $t("delete") }}
                      </div>
                    </div>
                    <div
                      slot="reference"
                      class="rounded p-1 px-3 bg-blue-200 text-blue-800 opacity-70 hover:opacity-100 cursor-pointer"
                    >
                      <i class="fas fa-ellipsis-v cursor-pointer" />
                    </div>
                  </el-popover>
                </div>
              </div>
            </div>
            <div class="px-4 md:px-6 py-3">
              <div class="ml-4 mb-5">
                <h4 class="font-bold text-gray-500 pb-3">
                  {{ $t("description") }}
                </h4>
                <div
                  v-html="ticket.description"
                  v-if="!editmode"
                  class="ml-2"
                />
                <Editor
                  v-if="editmode"
                  :body="descriptionEdit"
                  v-model="descriptionEdit"
                />
              </div>
              <div
                class="ml-4"
                v-if="ticket.damages && ticket.damages.length > 0"
              >
                <h4 class="font-bold text-gray-500 pb-3">
                  {{ $t("damages included") }} -
                  <router-link
                    class="px-2 cursor-pointer text-blue-600 hover:underline"
                    :to="{
                      name: 'Fleet-Detail',
                      params: { id: this.ticket.bike.id }
                    }"
                  >
                    {{ this.ticket.bike.name }}
                  </router-link>
                </h4>
                <!-- {{ ticket.damages }} -->

                <div
                  class="border px-4 py-2 border-blueGray-300 bg-white mb-2"
                  v-if="
                    ticket.checklist_result &&
                    ticket.checklist_result.checklist_id
                  "
                >
                  <div class="text-xs font-semibold">
                    {{ $t("checklist submitted") }}
                  </div>
                  <router-link
                    :to="{
                      name: 'CheckListResult',
                      params: {
                        id: ticket.checklist_result.checklist_id
                      },
                      query: {
                        result_id: ticket.checklist_result.id
                      }
                    }"
                  >
                    {{ $t("view results") }}
                  </router-link>
                </div>
                <div
                  v-for="(damage, dindex) in ticket.damages"
                  :key="damage.id + 'damage'"
                  class="border px-4 py-2 border-blueGray-300 bg-white"
                  :class="{
                    'border-t': dindex == 0,
                    'border-t-0': dindex != 0
                  }"
                >
                  <div class="text-xs font-semibold">
                    {{ $t("damage types:") }}
                  </div>
                  <span v-if="damage.type && damage.type.name">
                    {{ damage.type.name }}
                  </span>
                  <div class="pt-1" v-if="damage.types">
                    <div
                      class="px-2 py-1 bg-rose-400 rounded text-rose-50 mr-2 mt-3 inline-block"
                      v-for="{ type, type2 } in damage.types"
                      :key="`${type.id}-types`"
                    >
                      {{ type.name }}
                      <span v-if="type2">
                        {{ type2.name }}
                      </span>
                    </div>
                  </div>
                  <div class="mt-4">
                    <div class="text-xs font-semibold">
                      {{ $t("notes:") }}
                    </div>
                    {{ damage.notes }}
                  </div>
                  <MediaGallery
                    v-if="
                      damage.damage_images && damage.damage_images.length > 0
                    "
                    :medias="damage.damage_images"
                  />
                  <!-- {{ damage.id }}
                  {{ damage.type.name }}
                  {{ damage.notes }} -->
                </div>
              </div>
              <div class="ml-4" v-if="customData">
                <h4 class="font-bold text-gray-500 pb-3">
                  {{ $t("custom data") }}
                </h4>
                <div class="ml-2 mb-2" v-for="col in customData" :key="col.key">
                  <div class="font-semibold text-gray-400">
                    {{ $t(col.name) }}
                  </div>
                  <div class="ml-2">
                    <input
                      v-if="editmode"
                      class="px-4 py-2 w-full"
                      v-model="col.value"
                    />
                    <div class="" v-if="!editmode">
                      {{ ticket.custom_data[col.key] }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="px-4 md:px-10"
              v-for="(activity, index) in ticket.activities"
              :key="`activity-${activity.id}`"
            >
              <TimelineLayout
                v-if="activity.action == 'UPDATE_TICKET_MESSAGES'"
                :message="messageById[activity.changes.message_id]"
                :user="activity.user"
                :is_external="activity.user_id == null"
                :showConnection="ticket.activities.length - 1 != index"
              />
              <TimelineLayout
                v-else
                :user="activity.user"
                :showConnection="ticket.activities.length - 1 != index"
              >
                <template v-slot:right>
                  <div
                    class="bg-gray-50 px-4 py-2 border"
                    style="margin-left: -1px"
                  >
                    <div v-if="activity.action == 'UPDATE_TICKET_CLOSE'">
                      <span class="text-xs text-gray-400 font-semibold">
                        {{ $t("closed the ticket") }}
                      </span>
                    </div>
                    <div v-else-if="activity.action == 'UPDATE_TICKET_REOPEN'">
                      <span class="text-xs text-gray-400 font-semibold">
                        {{ $t("reopened the ticket") }}
                      </span>
                    </div>
                    <div
                      v-else-if="activity.action == 'NOTIFY_TICKET_TO_SUPPLIER'"
                    >
                      <span class="text-xs text-gray-400 font-semibold">
                        {{ $t("notified supplier") }}
                      </span>
                      <div>
                        <span class="font-semibold">{{ $t("subject") }}:</span>
                        {{ activity.changes.subject }}
                      </div>
                      <span class="font-semibold">{{ $t("message") }}:</span>
                      <div v-html="activity.changes.message" />
                      <span class="font-semibold">{{ $t("medias") }}:</span>
                      <ResultMedias
                        :medias="activity.changes.uploads"
                        v-if="
                          activity.changes.uploads &&
                          activity.changes.uploads.length > 0
                        "
                      />
                    </div>
                    <div v-else-if="activity.action == 'UPDATE_TICKET_TAGS'">
                      <div
                        v-if="
                          'tags' in activity.changes &&
                          activity.changes.tags.length > 0
                        "
                      >
                        <span class="text-xs text-gray-400 font-semibold">
                          {{ $t("updated tags") }}
                        </span>
                        <LabelDisplay
                          v-for="tag in activity.changes.tags"
                          :label="tag"
                          :key="tag.name"
                        />
                      </div>
                      <span v-else>
                        <span class="text-xs text-gray-400 font-semibold">
                          {{ $t("removed all tags") }}
                        </span>
                      </span>
                    </div>
                    <div
                      v-else-if="
                        activity.action == 'UPDATE_TICKET_INTERNAL_RECIPIENTS'
                      "
                    >
                      <div
                        class="flex flex-wrap items-center"
                        v-if="
                          'internal_recipients' in activity.changes &&
                          activity.changes.internal_recipients.length > 0
                        "
                      >
                        <span class="text-xs text-gray-400 font-semibold">
                          {{ $t("updated internal recipients") }}
                        </span>
                        <div
                          class="flex bg-gray-100 mx-1 px-2 py-1 rounded"
                          v-for="internal_recipient in activity.changes
                            .internal_recipients"
                          :user="internal_recipient"
                          :key="internal_recipient.name"
                        >
                          <div class="mx-1 flex items-center">
                            <ProfileImage
                              class="mr-1"
                              :bgNonWhite="true"
                              :isSmall="true"
                              :user="internal_recipient"
                            />
                            {{ internal_recipient.name }}
                          </div>
                        </div>
                      </div>
                      <span v-else>
                        <span class="text-xs text-gray-400 font-semibold">
                          {{ $t("removed all internal recipients") }}
                        </span>
                      </span>
                    </div>
                    <div v-else>
                      <div v-if="'hub_id' in activity.changes">
                        <span class="text-xs text-gray-400 font-semibold">
                          {{ $t("updated hubs") }} -
                        </span>
                        {{ hubsByID[activity.changes.hub_id].name }}
                      </div>
                      <div v-if="'type_id' in activity.changes">
                        <span class="text-xs text-gray-400 font-semibold">
                          {{ $t("updated type") }} -
                        </span>
                        {{ ticketTypeByID[activity.changes.type_id].name }}
                      </div>
                      <div v-if="'supplier_id' in activity.changes">
                        <span class="text-xs text-gray-400 font-semibold">
                          {{ $t("updated supplier") }} -
                        </span>
                        {{ suppliersByID[activity.changes.supplier_id].name }}
                      </div>
                      <div v-if="'bike_id' in activity.changes">
                        <span class="text-xs text-gray-400 font-semibold">
                          {{ $t("updated asset") }} -
                        </span>
                        {{ bikesByID[activity.changes.bike_id].name }}
                      </div>
                      <div v-if="'subject' in activity.changes">
                        <span class="text-xs text-gray-400 font-semibold">
                          {{ $t("updated title") }} -
                        </span>
                        {{ activity.changes.subject }}
                      </div>
                      <div v-if="'due_date' in activity.changes">
                        <span class="text-xs text-gray-400 font-semibold">
                          {{ $t("updated due date") }} -
                        </span>
                        {{ activity.changes.due_date }}
                      </div>
                      <div v-if="'description' in activity.changes">
                        <span class="text-xs text-gray-400 font-semibold">
                          {{ $t("updated description") }} -
                        </span>
                        <div v-html="activity.changes.description" />
                      </div>
                    </div>
                    <!-- workaround for not having the z for timezone -->
                    <span
                      class="text-xs ml-1 -mt-1 block"
                      v-if="
                        activity.inserted_at.charAt(
                          activity.inserted_at.length - 1
                        ) != 'Z'
                      "
                    >
                      ({{ getFromNowDateTime(activity.inserted_at + "Z") }})
                    </span>
                    <span class="text-xs ml-1 -mt-1 block" v-else>
                      ({{ getFromNowDateTime(activity.inserted_at) }})
                    </span>
                  </div>
                </template>
              </TimelineLayout>
            </div>
            <MediaGallery
              :medias="ticket.medias"
              :hasRemoveById="true"
              @removeMediaById="removeMediaById"
            />
            <!-- <Timeline :messages="ticket.messages" /> -->
            <div class="px-4 md:px-10">
              <div class="md:flex text-center">
                <div class="w-full">
                  <div class="relative text-left w-full">
                    <h3
                      class="px-4 pb-2 text-blueGray-400 uppercase font-bold text-md pt-5 relative flex items-center"
                    >
                      <i class="fas fa-comment-alt mr-2" />
                      {{ $t("add comments") }}
                    </h3>
                    <TimelineLayout
                      v-if="currentUser"
                      :user="currentUser"
                      :showConnection="false"
                      caretDistance="left: -0.96rem"
                    >
                      <template v-slot:right>
                        <TicketMessageForm
                          :ticket_id="ticket.id"
                          :is_closed="ticket.is_closed"
                          :damages="ticket.damages ? ticket.damages : []"
                        >
                          <template v-slot:uploadBtn>
                            <input
                              id="uploadFile"
                              type="file"
                              ref="uploadFile"
                              @change="fileChange"
                              accept="*"
                              hidden
                            />
                            <label
                              class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs my-3 px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                              for="uploadFile"
                            >
                              <i class="fas fa-cloud-upload-alt"></i>
                              {{ $t("upload file(s)") }}
                            </label>
                          </template>
                        </TicketMessageForm>
                      </template>
                    </TimelineLayout>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SlideUp>
    </div>
    <el-dialog
      :visible.sync="sendEmailDialog"
      width="50%"
      custom-class="bg-blueGray-100-important"
    >
      <SendToSupplierForm
        @close="sendEmailDialog = false"
        :supplier="supplier"
        :ticketId="ticket.id"
        :damages="ticket.damages ? ticket.damages : []"
      />
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import formatting from "../mixins/formatting";
import SlideUp from "@/components/transitions/SlideUp.vue";
import TicketMessageForm from "@/components/TicketMessageForm.vue";
import LabelDisplay from "@/components/LabelDisplay.vue";
import ResultMedias from "../components/checklist/result/ResultMedias.vue";
// import Timeline from "@/components/Timeline.vue";
// import ListTable from "@/components/ListTable.vue";
import TimelineLayout from "@/components/TimelineLayout.vue";
import PopoverWrapper from "@/components/PopoverWrapper.vue";
import ProfileImage from "@/components/ProfileImage.vue";
import moment from "moment";
// import QrCode from "@/components/QrCode.vue";
import MediaGallery from "../components/MediaGallery.vue";
import Editor from "../components/Editor.vue";
import SendToSupplierForm from "../components/SendToSupplierForm.vue";

export default {
  name: "TicketDetail",
  components: {
    // QrCode,
    SlideUp,
    LabelDisplay,
    // Timeline,
    // ListTable,
    ResultMedias,
    TimelineLayout,
    TicketMessageForm,
    ProfileImage,
    PopoverWrapper,
    MediaGallery,
    Editor,
    SendToSupplierForm
  },
  mixins: [formatting],
  computed: {
    ...mapState("users", ["users"]),
    ...mapState("tickets", ["ticket", "ticketConfig"]),
    ...mapState("tags", ["ticket_tags"]),
    ...mapState("typeDefinitions", ["ticketDefinitions"]),
    ...mapState("suppliers", ["suppliers"]),
    ...mapState("hubs", ["hubs"]),
    ...mapState("bikes", ["bikes"]),
    ...mapGetters("hubs", ["hubsByID"]),
    ...mapGetters("typeDefinitions", ["ticketTypeByID"]),
    ...mapGetters("suppliers", ["suppliersByID"]),
    ...mapGetters("bikes", ["bikesByID"]),
    statuses() {
      return this.$store?.state?.tags?.bike_status_labels
        ? this.$store.state.tags.bike_status_labels
        : [];
    },
    bike() {
      return this.$store?.state?.bike?.bike;
    },
    damageMedias() {
      return this.ticket.damages.reduce(
        (accu, damage) => accu.concat(damage.damage_images),
        []
      );
    },
    messageById() {
      return this.ticket.messages.reduce((accu, message) => {
        accu[message.id] = message;
        return accu;
      }, {});
    },
    supplier() {
      return this?.ticket?.bike?.supplier_id
        ? this.suppliers.find(
            supplier => supplier.id == this.ticket.bike.supplier_id
          )
        : {};
    },
    options() {
      return {
        ticketDefinitions: this.ticketDefinitions,
        suppliers: this.suppliers,
        hubs: this.hubs,
        bikes: this.bikes,
        users: this.users
      };
    },
    qrName() {
      return this.ticket.subject ? this.ticket.subject : this.ticket.id;
    },
    currentUser() {
      return this.$store?.state?.accounts?.user;
    },
    // customDataCols() {
    //   return this.ticketConfig?.schema?.columns;
    // },
    dueDateStatus() {
      const due = moment(this.ticket.due_date).unix();
      const today = moment().startOf("day").unix();
      if (isNaN(due)) {
        return "N/A";
      }
      if (due == today) {
        return "Due today";
      }
      if (due < today) {
        return "Past due";
      }
      return "";
    }
  },
  watch: {
    ticket() {
      this.$store.dispatch("bike/getBikeWithCb", {
        id: this.ticket.bike_id,
        cb: ({ data }) => {
          this.bikeStatus = data.status_id;
        }
      });

      this.dueDateEdit = this.ticket.due_date;
      this.subjectEdit = this.ticket.subject;
      this.descriptionEdit = this.ticket.description;
      this.selectedTags = this.ticket.tags.reduce((accu, tag) => {
        accu[tag.id] = tag;
        return accu;
      }, {});
      this.selectedUsers = this.ticket.internal_recipients.reduce(
        (accu, ir) => {
          accu[ir.id] = ir;
          return accu;
        },
        {}
      );
      if (this.customData) {
        this.customData = this.customData.map(col => {
          col.value = this.ticket?.custom_data?.[col.key];
          return col;
        });
      }
    }
  },
  data() {
    return {
      sendEmailDialog: false,
      editmode: false,
      dueDateEdit: null,
      subjectEdit: null,
      descriptionEdit: null,
      customData: [],
      selectedTags: {},
      selectedUsers: {},
      bikeStatus: null,
      selects: [
        {
          name: "types",
          key: "type_id",
          value: "",
          options: "ticketDefinitions",
          optionLabelKey: "name"
        },
        {
          name: "supplier",
          key: "supplier_id",
          value: "",
          options: "suppliers",
          optionLabelKey: "name"
        },
        {
          name: "hub",
          key: "hub_id",
          value: "",
          options: "hubs",
          optionLabelKey: "name"
        },
        {
          name: "asset",
          key: "bike_id",
          value: "",
          options: "bikes",
          optionLabelKey: "name"
        }
      ]
    };
  },
  methods: {
    updateBikeStatus(status_id) {
      this.$store.dispatch("bike/updateBike", {
        id: this.bike.id,
        status_id
      });
    },
    removeMediaById(upload_id) {
      this.$store.dispatch("upload/removeTicketMedia", {
        ticket_id: this.$route.params.id,
        upload_id: upload_id
      });
    },
    lastPage() {
      // this.$router.push({
      //   name: "Ticket"
      // });
      this.$router.back();
    },
    selectChange(id, { key }) {
      const ticket = {
        [key]: id
      };
      this.$store.dispatch("tickets/updateTicket", {
        id: this.$route.params.id,
        ticket
      });
    },
    handleDelete() {
      const deleteString = `${this.$t("Confirm delete ticket")}: ${
        this.ticket.subject
      }`;
      this.$confirm(deleteString).then(() => {
        this.$store.dispatch("tickets/deleteTicket", {
          ticket: this.ticket
        });
      });
    },
    exportBikes() {
      this.$store.dispatch("exportData/exportBikes", {
        ids: this.ticket.bikes.map(({ id }) => parseInt(id))
      });
    },
    handleTagChange(tags) {
      this.$store.dispatch("tickets/updateTicketTag", {
        id: this.$route.params.id,
        tags: Object.keys(tags).map(tag => parseInt(tag))
      });
    },
    handleUserChange(internal_recipients) {
      this.$store.dispatch("tickets/updateTicketUser", {
        id: this.$route.params.id,
        internal_recipients: Object.keys(internal_recipients).map(
          internal_recipient => {
            return parseInt(internal_recipient);
          }
        )
      });
    },
    updateTicket() {
      let custom_data = this.customData
        ? this.customData.reduce((accu, item) => {
            accu[item.key] = item.value;
            return accu;
          }, {})
        : {};
      let ticket = {
        subject: this.subjectEdit,
        description: this.descriptionEdit,
        custom_data
      };
      if (this.dueDateEdit) {
        ticket.due_date = moment(this.dueDateEdit).format("YYYY-MM-DD");
      }
      this.$store.dispatch("tickets/updateTicket", {
        id: this.$route.params.id,
        ticket
      });
      this.editmode = false;
    },
    fileChange() {
      let formData = new FormData();
      formData.append("file", this.$refs.uploadFile.files[0]);
      formData.append(`action`, "ticket");
      formData.append(`ticket_number`, this.ticket.id);
      this.$store.dispatch("upload/upload", {
        payload: formData,
        // need to fix this jsut faking it for now so the iamge will hopefully be there
        cb: () => setTimeout(this.getTicketData, 5000)
      });
    },
    getTicketData() {
      this.$store.dispatch("tickets/getTicket", this.$route.params.id);
    }
  },
  created() {
    if (this.$route.params.id != "new") {
      this.getTicketData();
    }

    this.$store.dispatch("typeDefinitions/getAllTicketDefinitions");
    this.$store.dispatch("suppliers/getAllSuppliers");
    this.$store.dispatch("users/getAllUsers");
    this.$store.dispatch("bikes/getAllBikes", { noFilter: true });
    this.$store.dispatch("hubs/getAllHubs");

    this.$store.dispatch("tags/getAllTicketTags");
    this.$store.dispatch("tags/getAllBikeStatusLabels");
    this.$store.dispatch("tickets/getTicketSetting", {
      cb: data => {
        this.customData = data?.schema?.columns.map(col => {
          col.value = this.ticket?.custom_data?.[col.key];
          return col;
        });
      }
    });
  }
};
</script>

<style>
.pzero-popover {
  padding: 0 !important;
}
</style>
