<template>
  <el-popover
    trigger="click"
    @after-enter="handleUpdateItemEnter"
    @after-leave="handleUpdateItem"
    popper-class="pzero-popover"
  >
    <div class="w-72">
      <div class="p-3 pb-2">
        <input
          class="border px-3 py-2 w-full focus:outline-none"
          :placeholder="$t('search')"
          v-model="search"
        />
      </div>
      <div class="max-h-48 overflow-scroll border-t">
        <div
          class="flex justify-between cursor-pointer py-3 hover:bg-blueGray-100 rounded px-4"
          v-for="(item, index) in filteredItems"
          :key="`items-${item.id}`"
          @click="toggleItems(item)"
          :class="{ 'border-t': index != 0 }"
        >
          <div class="flex items-center justify-between w-full">
            <div class="flex items-center">
              <div
                class="h-5 w-5 rounded-full mr-1"
                :style="`background:${item.color};min-width: 1.25rem;`"
              />
              <ProfileImage
                class="mr-1"
                :isSmall="true"
                :user="item"
                :bgNonWhite="true"
                v-if="isUser"
              />
              <div class="font-semibold">
                {{ item.name }}
                <div
                  v-if="item.description"
                  class="text-gray-400 text-xs ml-1 -mt-1"
                >
                  {{ item.description }}
                </div>
              </div>
            </div>

            <i
              class="fas fa-check text-green-500 ml-1"
              v-if="item.id in selectedItems"
            />
          </div>
          <i v-if="item.show" class="fas fa-check text-green-500 ml-1" />
        </div>
      </div>
      <div v-if="hasLabelLink" class="border-t py-3 text-center font-semibold">
        <router-link :to="{ name: 'Label' }">
          <i class="far fa-edit"></i> {{ $t("edit labels") }}
        </router-link>
      </div>
    </div>
    <i slot="reference" class="fas fa-ellipsis-v cursor-pointer" />
  </el-popover>
</template>

<script>
import ProfileImage from "./ProfileImage.vue";
export default {
  name: "PopoverWrapper",
  components: { ProfileImage },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    selectedItems: {
      type: Object,
      default: () => {}
    },
    hasLabelLink: {
      type: Boolean,
      default: true
    },
    isUser: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      search: "",
      selected: {},
      itemChanged: false
    };
  },
  computed: {
    filteredItems() {
      return this.search == ""
        ? this.items
        : this.items.filter(
            ({ name }) =>
              name && name.toLowerCase().includes(this.search.toLowerCase())
          );
    }
  },
  watch: {
    selectedItems() {
      this.selected = Object.keys(this.selectedItems)
        .filter(key => !isNaN(key))
        .reduce((accu, key) => {
          accu[key] = this.selectedItems[key];
          return accu;
        }, {});
    }
  },
  methods: {
    handleUpdateItemEnter() {
      this.search = "";
      this.itemChanged = false;
    },
    handleUpdateItem() {
      if (this.itemChanged) {
        this.$emit("handleItemChange", this.selected);
      }
    },
    toggleItems(item) {
      this.itemChanged = true;
      if (item.id in this.selectedItems) {
        this.$delete(this.selectedItems, item.id);
        this.$delete(this.selected, item.id);
      } else {
        this.$set(this.selectedItems, item.id, item);
        this.$set(this.selected, item.id, item);
      }
    }
  }
};
</script>

<style>
.pzero-popover {
  padding: 0 !important;
}
</style>
