<template>
  <div>
    <label for="">
      {{ $t("subject") }}
    </label>
    <input
      v-model="subject"
      class="w-full ounded focus:outline-none px-5 py-3 mb-2 border"
    />
    <div class="flex flex-col py-2" v-if="supplier.contacts">
      <div
        v-for="contact in supplier.contacts"
        :key="contact.email"
        class="cursor-pointer"
        @click="toggleSelectContact(contact)"
      >
        <div class="rounded mr-2 px-3 py-1 font-semibold flex items-center">
          <div class="w-5">
            <i
              v-if="contact.email in selectedContact"
              class="fas fa-check-circle"
            />
          </div>

          {{ contact.name }} ({{ contact.email }})
          <div
            class="rounded px-1 bg-blue-500 text-blue-50 ml-2"
            v-if="contact.default"
          >
            {{ $t("default") }}
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      {{ $t("no contacts in supplier") }}
    </div>
    <Editor :body="message" v-model="message" />
    <button
      class="mr-2 font-semibold mt-2 focus:outline-none"
      @click="selectAllImage"
    >
      {{ $t("select all") }}
    </button>
    <button
      class="mr-2 font-semibold mt-2 focus:outline-none"
      @click="deselectAllImage"
    >
      {{ $t("deselect all") }}
    </button>
    <!-- <div>
      <div v-for="damage in damages" :key="damage.id">
        {{ damage.type.name }}
      </div>
    </div> -->
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 py-4">
      <SendToSupplierImageSelect
        v-for="image in images"
        :key="image.path"
        :image="image"
        :selected="selectedImages[image.id]"
        @handleSelect="handleSelect"
      />
    </div>
    <div>
      <button
        class="bg-blue-500 px-3 py-1 text-blue-50 rounded"
        @click="notify"
      >
        {{ $t("notify") }}
      </button>
    </div>
  </div>
</template>

<script>
import Editor from "./Editor.vue";

const SendToSupplierImageSelect = {
  name: "SendToSupplierImageSelect",
  props: {
    image: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    handleSelect() {
      this.$emit("handleSelect", this.image.id);
    }
  },
  render() {
    let klass = `fas absolute text-2xl text-green-600 ${
      this.selected ? "fa-check-square " : "fa-square"
    }`;
    return (
      <div class="relative cursor-pointer" onClick={this.handleSelect}>
        <i class={klass} />
        <img src={this.image.path} />
      </div>
    );
  }
};

export default {
  name: "SendToSupplierForm",
  props: {
    ticketId: {
      type: Number,
      required: true
    },
    damages: {
      type: Array,
      default: () => []
    },
    supplier: {
      type: Object,
      default: () => {}
    }
  },
  components: { Editor, SendToSupplierImageSelect },
  computed: {
    images() {
      return this.damages.reduce(
        (accu, damage) => accu.concat(damage.damage_images),
        []
      );
    }
  },
  data() {
    return {
      selectedContact: {},
      selectedImages: {},
      subject: "",
      message: ""
    };
  },
  methods: {
    toggleSelectContact(contact) {
      if (contact?.email in this.selectedContact) {
        this.$delete(this.selectedContact, contact?.email);
      } else {
        this.$set(this.selectedContact, contact?.email, contact);
      }
    },
    notify() {
      let to = Object.keys(this.selectedContact);
      let selectedImages = Object.keys(this.selectedImages);
      if (this.images.length > 0 && selectedImages.length == 0) {
        this.$notify.error({
          title: "Error",
          message: "no media selected"
        });
        return;
      }
      if (to.length == 0) {
        this.$notify.error({
          title: "Error",
          message: "no supplier contacts selected"
        });
        return;
      }
      if (this.subject == "") {
        this.$notify.error({
          title: "Error",
          message: "no subject added"
        });
        return;
      }
      this.$store.dispatch("tickets/notifySupplier", {
        id: this.ticketId,
        ticket: {
          subject: this.subject,
          to,
          message: this.message,
          uploads: Object.keys(this.selectedImages),
          action: "NOTIFY_SUPPLIER"
        },
        cb: () => {
          this.selectedImages = {};
          this.subject = "";
          this.message = "";
          this.$emit("close");
        }
      });
    },
    selectAllImage() {
      this.selectedImages = this.images.reduce((accu, img) => {
        accu[img.id] = true;
        return accu;
      }, {});
    },
    deselectAllImage() {
      this.selectedImages = this.images.reduce((accu, img) => {
        accu[img.id] = false;
        return accu;
      }, {});
    },
    handleSelect(selectedId) {
      if (this.selectedImages?.[selectedId]) {
        this.selectedImages[selectedId] = !this.selectedImages?.[selectedId];
      } else {
        this.$set(this.selectedImages, selectedId, true);
      }
    }
  },
  created() {
    this.selectedContact = this.supplier.contacts.reduce((accu, contact) => {
      if (contact.default) {
        accu[contact.email] = contact;
      }
      return accu;
    }, {});
  }
};
</script>
