<template>
  <div class="flex-auto w-full">
    <Editor :body="body" v-model="body" />
    <!-- <textarea
      v-model="body"
      class="border-gray-300 border-2 w-full rounded h-36 p-2"
    /> -->
    <div class="flex justify-between">
      <div>
        <button
          class="border border-emerald-500 bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs my-3 px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
          @click="handleCommentting"
        >
          <i class="fas fa-comment-alt" /> {{ $t("comment") }}
        </button>
        <button
          class="border bg-warmGray-50 border-warmGray-300 text-warmGray-600 active:bg-emerald-600 font-bold uppercase text-xs my-3 px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
          @click="handleCloseTicket"
          v-if="is_closed"
        >
          <i class="fas fa-check-circle" /> {{ $t("reopen ticket") }}
        </button>
        <button
          class="border bg-rose-50 border-rose-300 text-rose-600 active:bg-emerald-600 font-bold uppercase text-xs my-3 px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
          @click="preHandleCloseTicket"
          v-else
        >
          <i class="fas fa-times-circle" /> {{ $t("close ticket") }}
        </button>
      </div>
      <slot name="uploadBtn" />
    </div>

    <el-dialog :visible.sync="closeTicketDialog" width="400px">
      <div class="text-gray-500 font-semibold mb-2">
        {{ $t("mark following issues as resolved") }}:
      </div>
      <div
        v-for="(damage, dindex) in damages"
        :key="damage.id + 'damageid-select'"
        class="border px-4 py-2 border-blueGray-300 bg-white cursor-pointer"
        :class="{
          'border-t': dindex == 0,
          'border-t-0': dindex != 0
        }"
        @click="toggleSelectedDamages(damage)"
      >
        <i
          v-if="selectedDamages[damage.id] != null"
          class="fas fa-check-square mr-2"
        />
        <i
          v-if="selectedDamages[damage.id] == null"
          class="fas fa-square mr-2"
        />
        <span v-if="damage.type && damage.type.name">
          {{ damage.type.name }}
        </span>
        <template v-if="damage.types">
          <span
            class="px-2 py-1 bg-rose-400 rounded text-rose-50 mr-2 mt-3"
            v-for="t in damage.types"
            :key="`${t.id}-types`"
          >
            {{ t.name }}
          </span>
        </template>
      </div>
      <span slot="footer" class="dialog-footer">
        <button
          class="border-blue-600 border text-blue-600 px-3 py-1 mx-2 rounded"
          @click="closeTicketDialog = false"
        >
          {{ $t("Cancel") }}
        </button>
        <button
          class="bg-blue-600 text-green-50 px-3 py-1 mx-2 rounded"
          type="primary"
          @click="closeTicketAndIssues"
        >
          {{ $t("Confirm") }}
        </button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Editor from "@/components/Editor.vue";
const initialState = () => {
  return {
    selectedDamages: {},
    closeTicketDialog: false,
    body: ""
  };
};

export default {
  name: "TicketMessageForm",
  props: {
    ticket_id: {
      type: Number,
      required: true
    },
    is_closed: {
      type: Boolean,
      required: true
    },
    damages: {
      type: Array,
      default: () => []
    }
  },
  components: { Editor },
  data() {
    return initialState();
  },
  methods: {
    resetInitialState() {
      Object.assign(this.$data, initialState());
    },
    toggleSelectedDamages(damage) {
      if (this.selectedDamages[damage.id] != null) {
        this.selectedDamages[damage.id] = null;
      } else {
        this.$set(this.selectedDamages, damage.id, damage);
      }
    },
    preHandleCloseTicket() {
      if (
        this.damages.length > 0 &&
        this.damages.findIndex(damage => damage.read == false) > -1
      ) {
        this.closeTicketDialog = true;
      } else {
        this.handleCloseTicket();
      }
    },
    closeTicketAndIssues() {
      this.handleCloseTicket();
      this.handleToggleResolve();
      this.closeTicketDialog = false;
    },
    handleToggleResolve() {
      Object.values(this.selectedDamages)
        .filter(d => d != null)
        .forEach(({ id, bike_id }) => {
          const payload = { bike_damage: { id, bike_id, read: true } };
          this.$store.dispatch("damages/updateDamage", payload);
        });
    },
    handleCloseTicket() {
      this.$store.dispatch("tickets/closeTicket", {
        id: this.ticket_id,
        is_closed: !this.is_closed
      });
    },
    async handleCommentting() {
      const { ticket_id, body } = this;
      const posted = await this.$store.dispatch("tickets/commentTicket", {
        ticket_id,
        body
      });
      if (posted) {
        this.body = "";
      }
    }
  },
  created() {
    this.damages.forEach(damage =>
      this.$set(this.selectedDamages, damage.id, damage)
    );
  }
};
</script>

<style>
.el-dialog__body {
  display: block;
  justify-content: center;
  justify-items: center;
  align-items: center;
  height: 100%;
}
</style>
